import mobile from 'assets/Services/Mobile.svg';
import frontend from 'assets/Services/Frontend.svg';
import backend from 'assets/Services/Backend.svg';
import c1 from 'assets/Services/1C.svg';

export const services = [
  {
    title: 'Мобильная- <br/> разработка',
    techs: ['IOS', 'Android', 'Flutter', 'React Native'],
    description: `Обеспечиваем полный цикл разработки: предлагаем дизайн, разрабатываем цифровое решение, тестируем и обеспечиваем техподдержку`,
    image: mobile,
  },
  {
    title: 'Frontend- <br/>разработка',
    techs: ['JavaScript', 'React', 'Angular', 'Vue'],
    description: `Разрабатываем frontend с нуля, создаем сложные интерфейсы, проектируем архитектуру, дописываем компоненты и разбираемся в чужом коде`,
    image: frontend,
  },
  {
    title: 'Backend- <br/>разработка',
    techs: ['Java', '.NET', 'Python', 'PHP', 'Node', 'Ruby', 'Golang', 'Scala', 'C/C++'],
    description: `Используем передовые инженерные решения, разрабатываем backend любой сложности, обеспечиваем высокую масштабируемость и отказоустойчивость`,
    image: backend,
  },
  {
    title: '1C- <br/>разработка',
    techs: ['1C: ERP 2.0', '1C: ЗУП КОРП', '1C: Fresh', ' 1C-Битрикс', '1С: Конвертация данных'],
    description: `Внедряем прикладные программные решения на платформе 1С, применяем типовые конфигурации, обеспечиваем интеграцию с работающими системами`,
    image: c1,
    link: '/1C',
  },
];
