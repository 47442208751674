export const mainGreetingsData = {
  title: 'Эффективные ИТ-решения для развития бизнеса',
  description:
    '              Увеличьте доходы вашего продукта с помощью эффективного ПО, созданного специально для вашего бизнеса!',
  bullets: [
    { title: '400+', subTitle: 'проектов' },
    { title: '2500+', subTitle: 'сотрудников' },
  ],
};

export const bodyshopGreetingsData = {
  title: 'Талантливые ИТ-специалисты для вашего проекта',
  description:
    'Усилим вашу команду нашей многолетней ИТ-экспертизой. Более 300 компаний уже реализовали свои идеи с помощью экспертов ASTON.',
  bullets: [
    { title: '3 дня', subTitle: 'на подбор кандидатов' },
    { title: '10 дней', subTitle: 'для старта на проекте' },
    { title: '75%', subTitle: 'проходят отбор' },
    { title: '2500+', subTitle: 'сотрудников' },
  ],
  sx: {
    titleStyle: {},
    bulletsStyle: {},
  },
};

export const mdsGreetingData = {
  title: 'Проектная разработка под ключ',
  description:
    'Более 17 лет создаём и\u00A0внедряем цифровые решения для лидеров рынка. Верим, что хорошо подобранная команда профессионалов приведёт к\u00A0успеху любой ИТ-проект',
  bullets: [
    { title: '2500+', subTitle: 'сотрудников' },
    { title: '300+', subTitle: 'завершенных проектов' },
    { title: '17+', subTitle: 'лет на рынке' },
    { title: '12 ', subTitle: 'месяцев гарантии' },
  ],
  bulletsSx: { w: { desktop: '240px' } },
};

export const fintechGreetingData = {
  title: 'Разработка финансового\u00A0ПО',
  description:
    'В команде ASTON — специалисты с\u00A0глубокими экспертными знаниями и\u00A0большим опытом работы в\u00A0финтех\u2011отрасли',
  bullets: [
    { title: '100+', subTitle: 'реализованных проектов' },
    { title: '5 лет', subTitle: 'средний срок сотрудничества' },
    { title: '300+', subTitle: 'инженеров финансового\u00A0ПО' },
    { title: '10 дней', subTitle: 'для старта на\u00A0проекте' },
  ],
  bulletsSx: { w: { laptop: '220px' }, fontSize: { laptop: '24px', mobile: '16px' } },
};
