import { Center, Img, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Arrow from 'assets/Icons/ArrowRightWhite.svg';

const LinkButton = ({ children, direction = 'right', ...props }) => {
  return (
    <Stack
      spacing="10px"
      direction={direction === 'left' ? 'row-reverse' : 'row'}
      {...props}
      align="center"
    >
      <Text textStyle="tag">{children}</Text>
      <Center
        borderRadius="full"
        bgColor="bg.black"
        p="4px"
        transition="transform .2s"
        transform={`rotate(${direction === 'left' ? '-180deg' : '0deg'})`}
      >
        <Img src={Arrow} />
      </Center>
    </Stack>
  );
};

export default LinkButton;
