import React, { memo } from 'react';
import { Box, Button, Flex, Grid, Img, Text, VStack } from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { goToForm } from 'shared/lib/goToForm';
import AnimatedText from 'shared/AnimatedText';
import GreetingBullet from 'shared/GreetingBullet';

const Greetings = ({
  title,
  subTitle = null,
  description,
  bullets = [],
  bulletsSx = null,
  titleSx = null,
  btnSx = null,
}) => {
  return (
    <Box boxSizing="border-box" w="full" pos="relative" zIndex={800}>
      <VStack
        w="full"
        spacing={{ mobile: '48px', tablet: '64px', laptop: '80px' }}
        align="start"
        pos="relative"
        zIndex={800}
        mb={{ mobile: '180px', tablet: '200px', laptop: '210px' }}
      >
        <Text
          as="h1"
          w="full"
          maxW={{ tablet: '395px', laptop: '1130px', desktop: '780px' }}
          textAlign="left"
          sx={titleSx}
        >
          {title}
        </Text>
        {subTitle && (
          <Text textStyle="h1" mt={{ laptop: '-40px', tablet: '-44px', mobile: '-24px' }}>
            {subTitle}
          </Text>
        )}
        <Button onClick={goToForm} fontSize={{ tablet: '24px', mobile: '14px' }} sx={btnSx}>
          Оставить заявку
        </Button>
      </VStack>

      <Flex
        direction={{ mobile: 'column', tablet: 'row' }}
        w="full"
        pos="relative"
        zIndex={800}
        gap="20px"
        justify="space-between"
        flex="1 1 0"
      >
        <Box>
          <Img
            h={{ mobile: '56px', tablet: 'fit-content' }}
            w={{ mobile: '44px', tablet: 'fit-content' }}
            src={commonAssets.icons.Aston}
            alt="ASTON"
          />
        </Box>
        <VStack spacing={{ mobile: '40px', tablet: '48px', laptop: '64px' }} align="start">
          <VStack
            spacing={{ mobile: '32px', tablet: '48px' }}
            maxW={{ tablet: '342px', laptop: '600px', desktop: '670px' }}
            align="start"
            {...bulletsSx?.container}
          >
            <AnimatedText duration={1100} as="h3" maxW={{ mobile: '500px', tablet: '720px' }}>
              {description}
            </AnimatedText>
            <Grid
              templateColumns="1fr 1fr"
              columnGap={{ mobile: '42px', tablet: '20px' }}
              rowGap={{ mobile: '32px', laptop: '40px' }}
              w="full"
              className="greetings__stats"
              {...bulletsSx?.grid}
            >
              {bullets.map((data, i) => (
                <GreetingBullet key={i} {...data} bulletSx={bulletsSx} />
              ))}
            </Grid>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};
export default memo(Greetings);
