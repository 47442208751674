import React, { memo } from 'react';

import Cooperation from 'widgets/Cooperation';
import ContactUs from 'widgets/ContactUs';
import Reviews from 'widgets/Reviews';
import Projects from 'widgets/Projects';
import Greetings from 'widgets/Greetings';
import ServicesMain from 'widgets/ServicesMain';
import AnimatedText from 'shared/AnimatedText';
import Wrapper from 'shared/Wrapper';
import BlockHeader from 'shared/BlockHeader';

import { mainGreetingsData } from 'constants/greetingsData';

const IndexPage = () => {
  return (
    <Wrapper>
      <Greetings {...mainGreetingsData} />
      <ServicesMain />
      <Projects
        HeaderRender={
          <BlockHeader>
            Разработки
            <br />и отраслевые решения
          </BlockHeader>
        }
      />
      <Cooperation
        HeaderRender={
          <AnimatedText
            duration={700}
            as="h2"
            className="coop-title"
            _after={{ content: `'.'`, color: 'bg.accent' }}
          >
            Выберите подходящую <br /> модель сотрудничества
          </AnimatedText>
        }
      />
      <Reviews />
      <ContactUs />
    </Wrapper>
  );
};

export default memo(IndexPage);
