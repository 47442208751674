import React, { memo } from 'react';
import { Flex, VStack, Text, Box, HStack, Link as ChakraLink } from '@chakra-ui/react';
import { Link as GastbyLink } from 'gatsby';
import Tag from 'shared/Tag';
import { IconButton } from 'shared/IconButton';
import { motion } from 'framer-motion';
import LinkButton from 'shared/LinkButton';

const ServiceCardMain = ({ title, techs = null, description, image, link = null, ...props }) => {
  return (
    <VStack
      as={motion.div}
      role="group"
      pos="relative"
      p={{ laptop: '48px', mobile: '24px' }}
      h={{ desktop: '800px', laptop: '634px', mobile: '440px' }}
      align="start"
      boxSizing="border-box"
      borderRadius={{ laptop: '48px', mobile: '24px' }}
      spacing={{ laptop: '24px', mobile: '20px' }}
      bgColor="bg.gray"
      overflow="hidden"
      transition="background-color 0.3s"
      _hover={{
        bgColor: 'bg.neonLight',
        justifyContent: 'start',
      }}
      {...props}
    >
      <VStack
        justify="end"
        spacing={{ laptop: '20px', mobile: '16px' }}
        w="full"
        align="start"
        role="group"
      >
        <HStack w="full" justify="space-between">
          <Text as="h3" textAlign="left" dangerouslySetInnerHTML={{ __html: title }}></Text>
          <Box
            hideFrom="laptop"
            transition="transform 0.2s"
            _groupHover={{ transform: 'rotate(-90deg)' }}
          >
            <IconButton type="angle" direction="right" disableHover />
          </Box>
        </HStack>
        <Flex wrap="wrap" gap={{ mobile: '8px', laptop: '12px' }}>
          {techs?.map(val => (
            <Tag key={val} p="8px 16px">
              {val}
            </Tag>
          ))}
        </Flex>
      </VStack>
      <VStack
        justify="start"
        align="start"
        w="full"
        spacing="20px"
        pos="relative"
        zIndex={5}
        flex="1 1"
        _before={{
          content: `''`,
          pos: 'absolute',
          boxSize: 'full',
          top: 0,
          left: 0,
          zIndex: 10,
          bgImage: image,
          bgSize: { laptop: '85% 85%', tablet: '90% 90%', mobile: '100% 100%' },
          bgRepeat: 'no-repeat',
          opacity: 1,
          visibility: 'visible',
          transition: 'opacity .2s, transform .2s,visibility .2s',
          bgPos: 'center center',
          transform: { laptop: 'translateY(40px)', mobile: 'translateY(0px)' },
          _groupHover: {
            transform: { laptop: 'translateY(50px)', mobile: 'translateY(0px)' },
            opacity: 0,
            visibility: 'hidden',
          },
        }}
      >
        <Text
          opacity={0}
          transition="opacity .2s, transform .2s"
          transform="translateY(-10px)"
          _groupHover={{ opacity: 1, transform: 'translateY(0)' }}
          textStyle="h1"
          fontWeight={500}
        >
          {description}
        </Text>
        {Boolean(link) && (
          <ChakraLink
            as={GastbyLink}
            to={link}
            opacity={0}
            transition="opacity .2s, transform .2s"
            transform="translateY(-10px)"
            _groupHover={{ opacity: 1, transform: 'translateY(0)' }}
            cursor="pointer"
          >
            <LinkButton>Подробнее</LinkButton>
          </ChakraLink>
        )}
      </VStack>
    </VStack>
  );
};
export default memo(ServiceCardMain);
