import React from 'react';
import { Text, Box } from '@chakra-ui/react';

const GreetingBullet = ({ title, subTitle, bulletSx }) => {
  return (
    <Box>
      <Text as="h2">{title}</Text>
      <Text textStyle="h1" color="#333531" {...bulletSx}>
        {subTitle}
      </Text>
    </Box>
  );
};

export default GreetingBullet;
